import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, browserHistory } from 'react-router-dom';
import Login from './containers/Login';
import AdminHome from './containers/AdminHome';
import configureStore from './configureStore';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { blue } from "@material-ui/core/colors";

const store = configureStore();
const history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#fff59d',
    },
  },
});

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route exact path="/" component={AdminHome} />
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  );
}


export default App;
