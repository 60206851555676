import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Avatar,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import {
  LockOutlined,
} from '@material-ui/icons';
import {
  loginRequested,
  clearLoginReducer,
  clearLoginInfo,
} from './actions';
import {
  loggedIn,
  loggedOut,
} from '../Auth/actions';
import {
  NOT_EXIST_ADDRESS_ERROR,
  INVALID_USER_TYPE_OFFICER,
  INVALID_USER_TYPE_WORKER,
  AUTH_ERROR,
  UPDATE_ERROR,
  OTHER_DEVICE_LOGIN_ERROR,
} from './constants';

const useStyles = makeStyles(theme => ({
  page: {
    backgroundColor: "whitesmoke",
    minHeight: "120vh",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3,0,2),
  },
}))

const loginSelector = state => state.loginReducer;
const authSelector = state => state.authReducer;

const Login = (props) => {
  // storeからstateを取得
  const loginReducer = useSelector(loginSelector);
  const authReducer = useSelector(authSelector);
  // storeに紐づくdispatchの取得
  const dispatch = useDispatch();
  const classes = useStyles();
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [pwdError, setPwdError] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    // ログイン状態でログイン画面表示しようとした時に、二個目のuseEffectでちゃんと遷移するか？
    dispatch(clearLoginReducer());
    return () => dispatch(clearLoginInfo());
  }, []);

  useEffect(() => {
    if (authReducer.isLoggedIn === true) {
      props.history.push('/');
    }
  }, [authReducer.isLoggedIn])

  const handleSubmit = e => {
    e.preventDefault();
    if (!checkBlank()) {
      return;
    } else {
      dispatch(clearLoginReducer());
      const loginInfo = {
        email: e.target.email.value,
        password: e.target.password.value,
        appType: "pc",
      }
      dispatch(loginRequested(loginInfo));
    }
  }

  useEffect(() => {
    if (loginReducer.error.length > 0) {
      setPwdError(true);
      let errorMessage = "";
      switch (loginReducer.error) {
        case NOT_EXIST_ADDRESS_ERROR:
          errorMessage = "メールアドレスとパスワードの組み合わせが一致しません。入力内容をご確認ください。";
          break;
        case AUTH_ERROR:
          errorMessage = "メールアドレスとパスワードの組み合わせが一致しません。入力内容をご確認ください。";
          break;
        case UPDATE_ERROR:
          errorMessage = "エラーが発生しました。再度ログインをお試しください。";
          break;
        case INVALID_USER_TYPE_OFFICER:
          errorMessage = "このメールアドレスではスマホアプリのご利用ができません。登録されているメールアドレスをご確認ください。";
          break;
        case INVALID_USER_TYPE_WORKER:
          errorMessage = "このメールアドレスでは管理者画面のご利用ができません。登録されているメールアドレスをご確認ください。";
          break;
        case OTHER_DEVICE_LOGIN_ERROR:
          errorMessage = "他の端末からこのメールアドレスを使用してログインした可能性があります。アプリを再インストール後、再度ログインをお試しください。";
          break;
        default:
          errorMessage = "エラーが発生しました。再度ログインをお試しください。";
          break;
      }
      setPwdErrorMessage(errorMessage);
//      setPwdErrorMessage("メールアドレスとパスワードの組み合わせが一致しません。入力内容をご確認ください。");
      return;
    }
  }, [loginReducer.error])

  const handleEmailChange = e => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  }

  function checkBlank() {
    if (email === "") {
      setEmailError(true);
      setEmailErrorMessage("メールアドレスを入力してください");
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (password === "") {
      setPwdError(true);
      setPwdErrorMessage("パスワードを入力してください");
    } else {
      setPwdError(false);
      setPwdErrorMessage("");
    }

    if (email === "" || password === "") {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="subtitle1">
          管理者用画面ログイン
        </Typography>
        <form className={classes.form} noValidate onSubmit={e => handleSubmit(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => handleEmailChange(e)}
            error={emailError}
            helperText={emailErrorMessage}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => handlePasswordChange(e)}
            error={pwdError}
            helperText={pwdErrorMessage}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            ログイン
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default Login;
