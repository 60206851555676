import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import OrgAdminAppBar from '../../components/OrgAdminAppBar';
import {
  Typography,
  Button,
  Paper,
  Select,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import {
  getCompanyRequested,
  getExportMonthsRequested,
  getExportDataRequested,
} from './actions'
import {
  RAW_DATA,
  SUMMARY,
  PAYROLL,
} from './constants';
import {
  checkLogin
} from '../../commons/auth';
import {
  loginSuccess,
} from '../Login/actions';
//moment.locale('ja');

const useStyles = makeStyles(theme => ({
  page: {
    backgroundColor: "whitesmoke",
    minHeight: "120vh",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.up(1024 + theme.spacing(2) * 2)]: {
      width: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  pageTitlePaper: {
    width: "auto",
  },
  pageTitle: {
    marginLeft: theme.spacing(16),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  operationTitle: {
    marginTop: theme.spacing(4),
  },
  contentPanel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3,2),
  },
  helperText: {
    fontSize: "14px",
  },
  monthSelector: {
    marginTop: theme.spacing(4),
  },
  formatSelector: {
    marginTop: theme.spacing(4),
  },
  radioButton:{
    fontSize: "12px",
    marginTop: theme.spacing(2),
  },
  downloadButton: {
    marginTop: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
}));

const adminHomeSelector = state => state.adminHomeReducer;
const authSelector = state => state.authReducer;
const loginSelector = state => state.loginReducer;

const AdminHome = (props) => {
  const adminHomeReducer = useSelector(adminHomeSelector);
  const authReducer = useSelector(authSelector);
  const loginReducer = useSelector(loginSelector);
  const dispatch = useDispatch();
  const classes =  useStyles();
  const [officerName, setOfficerName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedExportType, setSelectedExportType] = useState(RAW_DATA);
  const [exportMonths, setExportMonths] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fileName, setFileName] = useState("");

  let userId;

  useEffect(() => {
    checkLogin(authReducer, loginReducer)
    .then(response => {
      if (!response) {
        props.history.push('/login');
        return;
      } else if (response instanceof Object) {
        dispatch(loginSuccess(response));
      }
      userId = loginReducer.user._id;
      dispatch(getExportMonthsRequested(userId));
    })
    .catch(err => {
      console.error(err);
    })
  }, [])

  useEffect(() => {
    // 暫定でこの位置でユーザー名取得
    setOfficerName(loginReducer.user.name);
    if (typeof adminHomeReducer.exportMonths !== "undefined") {
      setSelectedMonth(adminHomeReducer.exportMonths[0]);
      setExportMonths(adminHomeReducer.exportMonths);
    }
  }, [adminHomeReducer.exportMonths]);

  useEffect(() => {
    setExportData(adminHomeReducer.exportData);
  }, [adminHomeReducer.exportData]);

  useEffect(() => {
    if (selectedMonth) {
      getExportData();
    }
  }, [selectedMonth])

//  const exportMonths = adminHomeReducer.exportMonths;
//  const [selectedMonth, setSelectedMonth] = React.useState(exportMonths[0]);
//  setSelectedMonth(state.adminHome.exportMonths[0]);

  const renderMonthSelector = () => {
    return (
      exportMonths.map((month, index) => (
        <MenuItem key={index} value={month}>{month}</MenuItem>
      ))
    )
  }

  function getExportData() {
    const exportCondition = {
      exportType: selectedExportType,
      exportMonth: moment(selectedMonth, "YYYYM").add(1, "month"),
    };

    if (selectedExportType === RAW_DATA) {
      setFileName(RAW_DATA + "_" + moment().format("YYYYMMDDhhmmss"));
    } else if (selectedExportType === SUMMARY) {
      setFileName(SUMMARY + "_" + moment().format("YYYYMMDDhhmmss"));
    } else if (selectedExportType === PAYROLL) {
      setFileName(PAYROLL + "_" + moment().format("YYYYMMDDhhmmss"));
    }

    dispatch(getExportDataRequested(exportCondition));
    setExportColumns(exportColumn);
  }

  const exportColumn =[
    {
      id: "worker",
      displayName: "作業員氏名"
    },
    {
      id: "startSite",
      displayName: "勤務開始現場"
    },
    {
      id: "startAddress",
      displayName: "勤務開始現場住所"
    },
    {
      id: "startDate",
      displayName: "勤務開始日"
    },
    {
      id: "startTime",
      displayName: "勤務開始時刻"
    },
    {
      id: "endSite",
      displayName: "勤務終了現場"
    },
    {
      id: "endAddress",
      displayName: "勤務終了現場住所"
    },
    {
      id: "endDate",
      displayName: "勤務終了日"
    },
    {
      id: "endTime",
      displayName: "勤務終了時刻"
    },
  ]

  const handleChange = e => {
    setSelectedMonth(e.target.value);
  }

  const handleChangeExportType = e => {
    setSelectedExportType(e.target.value);
  }

  return (
    <div className={classes.page}>
      <OrgAdminAppBar officerName={officerName} />
      <Paper className={classes.pageTitlePaper}>
        <Typography className={classes.pageTitle} variant="h6">ファイル出力</Typography>
      </Paper>
      <main className={classes.layout}>
        <Typography className={classes.operationTitle} >月と形式を指定してCSVファイルを出力する</Typography>
        <Paper className={classes.contentPanel}>
          <Typography>対象月を選択</Typography>
          <FormHelperText className={classes.helperText} >締日が15日で、2019年10月度を選択した場合、2019/09/16〜2019/10/15のデータを出力します</FormHelperText>
          <Select
            value={selectedMonth}
            onChange={handleChange}
            input={<Input name="month"
            className={classes.monthSelector} />}
          >
            { renderMonthSelector() }
          </Select>
          <Typography className={classes.formatSelector}>形式を選択</Typography>
          <RadioGroup
            value={selectedExportType} onChange={handleChangeExportType}
          >
            <FormControlLabel value={RAW_DATA} control={<Radio />} label={RAW_DATA} className={classes.radioButton} />
            <FormHelperText className={classes.helperText}>記録されているすべての勤怠データを一覧形式で出力します。</FormHelperText>
{/*
            <FormControlLabel value={SUMMARY} control={<Radio />} label={SUMMARY} className={classes.radioButton} />
            <FormHelperText className={classes.helperText}>記録されているすべての勤怠データを一覧形式で出力します。</FormHelperText>
            <FormControlLabel value={PAYROLL} control={<Radio />} label={PAYROLL} className={classes.radioButton} />
            <FormHelperText className={classes.helperText}>記録されているすべての勤怠データを一覧形式で出力します。</FormHelperText>
*/}
          </RadioGroup>
          <Divider className={classes.divider} />
          <CsvDownloader columns={exportColumns} datas={exportData} filename={fileName}>
            <Button variant="contained" color="primary" className={classes.downloadButton} >ダウンロード</Button>
{/*
            <Button variant="contained" color="primary" className={classes.downloadButton} onClick={(e) => getExportData(e)} >ダウンロード</Button>
*/}
          </CsvDownloader>
        </Paper>
      </main>
    </div>
  )
}

export default AdminHome;
