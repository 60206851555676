import axios from 'axios';
import {
  RAW_DATA,
  SUMMARY,
  PAYROLL,
} from './constants';
import config from '../config';

export async function getExportData(company, attendanceClosingDay, exportCondition, token) {
  let exportData;
  if (exportCondition.exportType === RAW_DATA) {
    exportData = await getExportRawData(company, attendanceClosingDay, exportCondition.exportMonth, token);
  } else if (exportCondition.exportType === SUMMARY) {
    exportData = await getExportSummaryData(company, exportCondition.exportMonth, token);
  } else if (exportCondition.exportType === PAYROLL) {
    exportData = await getExportPayrollData(company, exportCondition.exportMonth, token);
  }
  return exportData;
}

export async function getExportRawData(company, attendanceClosingDay, exportMonth, token) {
  return new Promise((resolve) => {
    axios.post(config.serverUrl + '/api/rawData', {
      company: company,
      attendanceClosingDay: attendanceClosingDay,
      exportMonth: exportMonth,
      token: token,
    })
    .then(response => {
      const exportData = response.data;
      resolve(exportData);
    })
    .catch(err => {
      console.log(err)
      console.error(new Error(err));
    })
  });
}

export async function getExportSummaryData(company, exportMonth, token) {
  return new Promise((resolve) => {
    axios.post(config.serverUrl + '/api/summaryData', {
      company: company,
      exportMonth: exportMonth,
      token: token,
    })
    .then(response => {
      const exportData = response.data;
      resolve(exportData);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}

export async function getExportPayrollData(company, exportMonth, token) {
  return new Promise((resolve) => {
    axios.post(config.serverUrl + '/api/payrollData', {
      company: company,
      exportMonth: exportMonth,
      token: token,
    })
    .then(response => {
      const exportData = response.data;
      resolve(exportData);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}
