import axios from 'axios';
import config from '../config';

export async function getCompany(_id, token) {
  const company = await getCompanyExecute(_id, token);
  return company;
}

export async function getCompanyExecute(_id, token) {
  return new Promise((resolve) => {
    axios.post(config.serverUrl + '/api/company', {
      _id: _id,
      token: token,
    })
    .then(response => {
      const company = response.data;
      resolve(company);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}
