import axios from 'axios';
import config from '../config';

export async function login(loginReducer) {
  return await loginExecute(loginReducer);
}

export async function loginExecute(loginReducer) {
  return new Promise((resolve, reject) => {
    const params = {
      email: loginReducer.email,
      password: loginReducer.password,
    }

    axios.post(config.serverUrl + '/api/user', {
      params,
    })
    .then(response => {
      const result = response.data;
      resolve(result);
    })
    .catch(err => {
      console.error(new Error(err));
      reject(err);
    })
  })
}


export async function getWorker(_id, token) {
  const worker = await getWorkerExecute(_id, token);
  return worker;
}

export async function getWorkerExecute(_id, token) {
  return new Promise((resolve) => {
/*
    axios.get(config.serverUrl + '/api/worker', {
      params: {
        _id: _id,
        token: token,
      }
    })
*/
    axios.post(config.serverUrl + '/api/worker', {
      _id: _id,
      token: token,
    })
    .then(response => {
      const worker = response.data;
      resolve(worker);
    })
    .catch(err => {
      console.log(err)
      console.error(new Error(err));
    })
  });
}

export async function getAllWorkers(company, token) {
  const workers = await getAllWorkersExecute(company, token);
  return workers;
}

export async function getAllWorkersExecute(company, token) {
  return new Promise((resolve, reject) => {
/*
    axios.get(config.serverUrl + '/api/workerAll', {
      params: {
        company: company,
        token: token,
      }
    })
*/
    axios.post(config.serverUrl + '/api/workerAll', {
      company: company,
      token: localStorage.getItem('token'),
    })
    .then(response => {
      const workers = response.data;
      resolve(workers);
    })
    .catch(err => {
      console.error(new Error(err));
      reject(err);
    })
  });
}
