import axios from 'axios';
import config from '../config';

export async function getSite(_id, token) {
  const site = await getSiteExecute(_id, token);
  return site;
}

export async function getSiteExecute(_id, token) {
  return new Promise((resolve) => {
/*
    axios.get(config.serverUrl + '/api/site', {
      params: {
        _id: _id,
        token: token,
      }
    })
*/
    axios.post(config.serverUrl + '/api/site', {
      _id: _id,
      token: token,
    })
    .then(response => {
      const site = response.data;
      resolve(site);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}

/*
export async function getSiteExecute(_id) {
  return new Promise((resolve) => {
    axios.get('/api/site', {
      params: {
        _id: _id,
      }
    })
    .then(response => {
      const site = response.data;
      resolve(site);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}
*/

export async function getAllSites(company, token) {
  const sites = await getAllSitesExecute(company, token);
  return sites;
}

export async function getAllSitesExecute(company, token) {
  return new Promise((resolve) => {
/*
    axios.get(config.serverUrl + '/api/siteAll', {
      params: {
        company: company,
        token: token,
      }
    })
*/
    axios.post(config.serverUrl + '/api/siteAll', {
      company: company,
      token: token,
    })
    .then(response => {
      const sites = response.data;
      resolve(sites);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}
/*
export async function getAllSitesExecute(company) {
  return new Promise((resolve) => {
    axios.get('/api/siteAll', {
      params: {
        company: company,
        token: localStorage.getItem('token'),
      }
    })
    .then(response => {
      const sites = response.data;
      resolve(sites);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}
*/
