import { handleActions } from 'redux-actions';
import {
  GET_COMPANY_REQUESTED,
  GET_COMPANY_FINISHED,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_EXPORT_MONTHS_REQUESTED,
  GET_EXPORT_MONTHS_SUCCESS,
  GET_EXPORT_DATA_REQUESTED,
  GET_EXPORT_DATA_SUCCESS,
  GET_EXPORT_DATA_FAILURE,
} from './actions';

const initialState = {
  _id: null,
  company: {},
  exportMonths: [],
  latestAttendance: {},
  exportCondition: "",
  exportData: [],
}

const adminHomeReducer = handleActions({
  [GET_COMPANY_REQUESTED]: (state, action) => ({
    ...state,
    _id: action.payload._id
  }),
  [GET_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    company: action.payload,
  }),
  [GET_EXPORT_MONTHS_REQUESTED]: (state, action) => ({
    ...state,
    _id: action.payload._id
  }),
  [GET_EXPORT_MONTHS_SUCCESS]: (state, action) => ({
    ...state,
    exportMonths: action.payload,
  }),
  [GET_EXPORT_DATA_REQUESTED]: (state, action) => ({
    ...state,
    exportCondition: action.payload,
  }),
  [GET_EXPORT_DATA_SUCCESS]: (state, action) => ({
    ...state,
    exportData: action.payload,
  }),
  [GET_EXPORT_DATA_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
}, initialState)

export default adminHomeReducer;
