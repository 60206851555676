import { combineReducers } from 'redux';
import authReducer from './containers/Auth/reducer';
import loginReducer from './containers/Login/reducer';
import adminHomeReducer from './containers/AdminHome/reducer';

const rootReducer = combineReducers({
  authReducer,
  loginReducer,
  adminHomeReducer,
});

export default rootReducer;
