import { handleActions } from 'redux-actions';
import {
  LOGGED_IN,
  LOGGED_OUT,
  CLEAR_AUTH_REDUCER,
} from './actions';

const initialState = {
  isLoggedIn: false,
}

const authReducer = handleActions({
  [LOGGED_IN]: (state, action) => ({
    ...state,
    isLoggedIn: true,
  }),
  [LOGGED_OUT]: (state, action) => ({
    ...state,
    isLoggedIn:false,
  }),
  [CLEAR_AUTH_REDUCER]: (state, action) => ({
    isLoggedIn: false,
  }),
}, initialState)

export default authReducer;
