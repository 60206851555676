import { put, call, takeEvery, select } from 'redux-saga/effects';
import {
  GET_COMPANY_REQUESTED,
  getCompanyFinished,
  getCompanySuccess,
  getCompanyFailure,
  GET_EXPORT_MONTHS_REQUESTED,
  getExportMonthsSuccess,
  GET_EXPORT_DATA_REQUESTED,
  getExportDataSuccess,
  getExportDataFailure,
} from './actions';
import axios from 'axios';
import { getAdminHomeReducer } from './selectors';
import { getLoginReducer } from '../Login/selectors';
import moment from 'moment';
import {
  getCompany
} from '../../commons/company';
import {
  getAllWorkers,
} from '../../commons/user';
import {
  getAllSites,
} from '../../commons/site';
import {
  getExportData
} from '../../commons/export';
moment.locale("ja", { weekdays: ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"], weekdaysShort: ["日","月","火","水","木","金","土"], });

export function* takeGetCompany() {
  try {
    console.log('take get company')
    const adminHomeReducer = yield select(getAdminHomeReducer);
    const loginReducer = yield select(getLoginReducer);
    const _id = loginReducer.user.company;
    const result = yield call(getCompany, _id, loginReducer.token);
    if (result === "NOT_EXIST_COMPANY_ERROR") {
      yield put(getCompanyFailure());
    } else {
      yield put(getCompanySuccess(result));
      return result;
    }
  } catch (error) {
    console.log(error);
    yield put(getCompanyFailure());
  } finally {
    yield put(getCompanyFinished());
  }
}

export function* takeGetExportMonths() {
  try {
    const adminHomeReducer = yield select(getAdminHomeReducer);
    const loginReducer = yield select(getLoginReducer);
    const _id = loginReducer.user.company;
    const result = yield call(getCompany, _id, loginReducer.token);
    if (result === "NOT_EXIST_COMPANY_ERROR") {
      yield put(getCompanyFailure());
    } else {
      yield put(getCompanySuccess(result));

      const exportMonths = yield call(getExportMounths, result, loginReducer.token);
      if (exportMonths === null) {
        return;
      } else {
        yield put(getExportMonthsSuccess(exportMonths))
        return;
      }
    }
  } catch (error) {
    console.log(error);
    yield put(getCompanyFailure());
  } finally {
    yield put(getCompanyFinished());
  }
}

const getExportMounths = (data) => {
  console.log(data)
  const attendanceClosingDay = data.attendanceClosingDay;
  const m = moment();
  const currentDay = m.format("DD");
  let exportMonths;

  if (attendanceClosingDay === undefined) {
    return;
  } else if (attendanceClosingDay === 0) {
    exportMonths = setExportMonths(m);
  } else {
    if (parseInt(currentDay) <= attendanceClosingDay) {
      exportMonths = setExportMonths(m);
    } else {
      exportMonths = setExportMonths(m.add(1,"months"));
    }
  }

  return exportMonths;
}

const setExportMonths = (m) => {
  const exportMonths = Array(6);
  for (let i = 0; i < exportMonths.length; i++) {
    if (i === 0) {
      exportMonths[i] = m.format("YYYY年M月度");
    } else {
      exportMonths[i] = m.subtract(1, "months").format("YYYY年M月度");
    }
  }
  return exportMonths;
}

export function* takeGetExportData() {
  try {
    const adminHomeReducer = yield select(getAdminHomeReducer);
    const loginReducer = yield select(getLoginReducer);
    const token = loginReducer.token;
    const company = loginReducer.user.company;
    const result = yield call(getExportData, loginReducer.user.company, adminHomeReducer.company.attendanceClosingDay, adminHomeReducer.exportCondition, token);

    if (result === "NO_DATA_ERROR") {
      yield put(getExportDataFailure());
    } else {
      const workers = yield call(getAllWorkers, company, token);
      const sites = yield call(getAllSites, company, token);
      for (let i in result) {
        const worker = workers.find((worker) => worker._id === result[i].worker);
        if (worker) {
          result[i].worker = worker.name;
        }

        if (result[i].startSite) {
          const startSite = sites.find((site) => site._id === result[i].startSite);
          if (startSite) {
            result[i].startSite = startSite.name;
          }
        }

        if (result[i].endSite) {
          const endSite = sites.find((site) => site._id === result[i].endSite);
          if (endSite) {
            result[i].endSite = endSite.name;
          }
        }

        if (result[i].startAddress !== undefined && result[i].startAddress.match(/,/)) {
          result[i].startAddress = result[i].startAddress.replace(/,/g, "，")
        }

        if (result[i].endAddress !== undefined && result[i].endAddress.match(/,/)) {
          result[i].endAddress = result[i].endAddress.replace(/,/g, "，")
        }

        let startDate = result[i].startDate;
        let startTime = result[i].startDate;
        result[i].startDate = moment(startDate).format("YYYY/MM/DD");
        result[i].startTime = moment(startTime).format("HH:mm");

        if (result[i].endDate) {
          let endDate = result[i].endDate;
          let endTime = result[i].endDate;
          result[i].endDate = moment(endDate).format("YYYY/MM/DD");
          result[i].endTime = moment(endTime).format("HH:mm");
        }
      }
      console.log(result)
      yield put(getExportDataSuccess(result));
    }
  } catch (error) {
    console.log(error);
    yield put(getExportDataFailure());
  } finally {
//    yield put(getCompanyFinished());
  }
}

const adminHomeSagas = [
  takeEvery(GET_COMPANY_REQUESTED, takeGetCompany),
  takeEvery(GET_EXPORT_MONTHS_REQUESTED, takeGetExportMonths),
  takeEvery(GET_EXPORT_DATA_REQUESTED, takeGetExportData),
]

export default adminHomeSagas;
