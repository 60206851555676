import { createAction } from 'redux-actions';

export const GET_COMPANY_REQUESTED = 'GET_COMPANY_REQUESTED';
export const GET_COMPANY_FINISHED = 'GET_COMPANY_FINISHED';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';
export const GET_EXPORT_MONTHS_REQUESTED = 'GET_EXPORT_MONTHS_REQUESTED';
export const GET_EXPORT_MONTHS_SUCCESS = 'GET_EXPORT_MONTHS_SUCCESS';
export const GET_EXPORT_DATA_REQUESTED = 'GET_EXPORT_DATA_REQUESTED';
export const GET_EXPORT_DATA_SUCCESS = 'GET_EXPORT_DATA_SUCCESS';
export const GET_EXPORT_DATA_FAILURE = 'GET_EXPORT_DATA_FAILURE';

export const getCompanyRequested = createAction(GET_COMPANY_REQUESTED, (_id) => ({_id: _id}));
export const getCompanyFinished = createAction(GET_COMPANY_FINISHED);
export const getCompanySuccess = createAction(GET_COMPANY_SUCCESS);
export const getCompanyFailure = createAction(GET_COMPANY_FAILURE);
export const getExportMonthsRequested = createAction(GET_EXPORT_MONTHS_REQUESTED, (_id) => ({_id: _id}));
export const getExportMonthsSuccess = createAction(GET_EXPORT_MONTHS_SUCCESS);
export const getExportDataRequested = createAction(GET_EXPORT_DATA_REQUESTED);
export const getExportDataSuccess = createAction(GET_EXPORT_DATA_SUCCESS);
export const getExportDataFailure = createAction(GET_EXPORT_DATA_FAILURE);
