import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  titleBar: {
    height: 64,
  },
  title: {
    fontWeight: "bold",
  },
  menuSelector: {
    fontSize: "16px",
    height: 64,
    position: "absolute",
    right: theme.spacing(2),
    color: "white",
  },
  menuArrowDropDown: {
    marginLeft: theme.spacing(2),
  },
}));

const OrgAdminAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography className={classes.title}>管理画面</Typography>
        <Button variant="text" className={classes.menuSelector}>
          {props.officerName} 様
{/*
          <ArrowDropDownIcon className={classes.menuArrowDropDown} />
*/}
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default OrgAdminAppBar;
