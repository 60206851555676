import { createAction } from 'redux-actions';

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_FINISHED = 'LOGIN_FINISHED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAR_LOGIN_REDUCER = 'CLEAR_LOGIN_REDUCER';
export const CLEAR_LOGIN_INFO = 'CLEAR_LOGIN_INFO';

export const loginRequested = createAction(LOGIN_REQUESTED);
export const loginFinished = createAction(LOGIN_FINISHED);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailure = createAction(LOGIN_FAILURE);
export const clearLoginReducer = createAction(CLEAR_LOGIN_REDUCER);
export const clearLoginInfo = createAction(CLEAR_LOGIN_INFO);
