import { put, call, takeEvery, select } from 'redux-saga/effects';
import {
  LOGIN_REQUESTED,
  loginFinished,
  loginSuccess,
  loginFailure,
} from './actions';
import axios from 'axios';
import { login } from '../../commons/user';
import { loggedIn } from '../Auth/actions';
import { getLoginReducer } from './selectors';

export function* takeLogin() {
  try {
    const loginReducer = yield select(getLoginReducer)
    const result = yield call(login, loginReducer)
    if (!result) {
      yield put(loginFailure("メールアドレスとパスワードの組み合わせが一致しません。入力内容をご確認ください。"));
      return;
    }
    if (result.token === undefined) {
      yield put(loginFailure(result));
    } else {
      if (result.user.userType === "Worker") {
        yield put(loginFailure("INVALID_USER_TYPE_WORKER"));
        return;
      }
      yield put(loginSuccess(result));
      localStorage.setItem('token', result.token);
      yield put(loggedIn());
    }
  } catch (error) {
    console.log(error);
    yield put(loginFailure(error.response.data));
  } finally {
    yield put(loginFinished());
  }
}

const loginSagas = [
  takeEvery(LOGIN_REQUESTED, takeLogin),
]

export default loginSagas;
