import axios from 'axios';
import config from '../config';

export async function checkLogin(authReducer, loginReducer) {
  // 既にログイン済みならOK
  if (authReducer.isLoggedIn) {
    return true;
  }

/* 発生しうるのか？ */
  // ログイン状態が不明だがreducerでtokenを所持している場合、
  // tokenで認証かける
  if (loginReducer.token) {
    const user = await checkToken(loginReducer.token);
    console.log(user)
    if (!user || user.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  // 未ログインでreducerにもtokenない場合、localStrageを捜索
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  } else {
    const user = await checkToken(token);
    if (!user || Object.keys(user).length === 0) {
      return false;
    } else {
      return {
        user: user,
        token: token,
      }
    }
  }
}

export async function checkToken(token) {
  return await checkTokenExecute(token);
}

export async function checkTokenExecute(token) {
  return new Promise((resolve) => {
    axios.post(config.serverUrl + '/api/checkToken', {
      token: token,
    })
    .then(response => {
      const user = response.data;
      resolve(user);
    })
    .catch(err => {
      console.error(new Error(err));
    })
  });
}
