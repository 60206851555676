import { handleActions } from 'redux-actions';
import {
  LOGIN_REQUESTED,
  LOGIN_FINISHED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CLEAR_LOGIN_REDUCER,
  CLEAR_LOGIN_INFO,
} from './actions';

const initialState = {
  email: "",
  password: "",
  appType: "",
  isLoginProcessing: false,
  user: {},
  token: "",
  error: {},
}

const loginReducer = handleActions({
  [LOGIN_REQUESTED]: (state, action) => ({
    ...state,
    email: action.payload.email,
    password: action.payload.password,
    appType: action.payload.appType,
    token: action.payload.token,
    isLoginProcessing: true,
  }),
  [LOGIN_FINISHED]: (state, action) => ({
    ...state,
    isLoginProcessing: false,
  }),
  [LOGIN_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload.user,
    token: action.payload.token,
  }),
  [LOGIN_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [CLEAR_LOGIN_REDUCER]: (state, action) => ({
    email: "",
    password: "",
    isLoginProcessing: false,
    user: {},
    error: {},
  }),
  [CLEAR_LOGIN_INFO]: (state, action) => ({
    ...state,
    email: "",
    password: "",
  }),
}, initialState)

export default loginReducer;
