import { all } from 'redux-saga/effects';
import loginSagas from './containers/Login/sagas';
import adminHomeSagas from './containers/AdminHome/sagas';

export default function* rootSaga() {
  yield all([
    ...loginSagas,
    ...adminHomeSagas,
  ])
}
